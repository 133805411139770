import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "components/Context";
import { useRecoilState } from "recoil";
import { LineHaveLicense} from "recoil/atoms";

function PrivateRoute({ children }) {
  const { authTokens } = useAuth();
  const refresh_token = localStorage.getItem('refresh_token');
  const [isLineHaveLicense] = useRecoilState(LineHaveLicense)

  // console.log(authTokens,"authTokens")
  if (!authTokens || authTokens === "undefined" || !refresh_token || refresh_token === 'undefined'  && !isLineHaveLicense) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />
  }
  else if(isLineHaveLicense){
    return <Navigate to="/licencseExpired"/>
  }
  return children;
}

export default PrivateRoute;