import React from "react";
import TypographyNDL from '../Typography/TypographyNDL';


function AvatarNDL(props) {

    return (
        <React.Fragment>
            {props.src ? 
        <div className={props.profileStyle ? "w-[100px] h-[100px] rounded-full" : "w-[24px] h-[24px]"}>
        <img
          className={props.className}
          style={props.style}
          src={props.src}
          alt={props.alt}
        />
      </div>
              
                :
                <div className={
                    `relative inline-flex items-center justify-center overflow-hidden 
                    bg-Neutral-neutral-base-alt dark:bg-Neutral-neutral-base-alt-dark w-[30px] h-[30px]
                      ${props.className}` + ""
                    // (props.profileStyle ? " w-[100px] h-[100px]" : "w-[30px] h-[30px]")
                  }>
                    <div>
                    <TypographyNDL value={props.initial} variant={ 'paragraph-xs'}  />
                    </div>
                 
                   
                </div>
            }

            
        </React.Fragment>
    );

}



export default AvatarNDL;